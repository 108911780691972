<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
        <v-dialog persistent v-model="isLoading">
            <v-row justify="center">
                <v-card width="400" height="100">
                    <center class="pa-5">
                        Editng Student data.<br />
                        Please Wait It will Take Time..
                    </center>
                </v-card>
            </v-row>
        </v-dialog>
        <v-card class="card">
            <v-card-title class="heading justify-center">Bulk Reset Username/Email</v-card-title>
            <v-card-text>
                <div class="add-section">
                    <v-row class="justify-content-between set-btn">
                        <div><a href="https://library-public.s3.ap-south-1.amazonaws.com/sample_csv/bulk_reset_prn.csv">
                                <v-btn class="mb-2 button"> <v-icon left dark>mdi-cloud-download</v-icon>Export CSV
                                    Template</v-btn></a>
                        </div>
                    </v-row>
                    <p class="file-warn">NOTE: *File should be in CSV format.</p>
                    <v-form ref="form">
                        <v-row class="justify-content-center">
                            <v-col lg="5" sm="12" md="5" class="import-box">
                                <label class="label"> Action </label><span style="color: red; font-weight: bolder">*</span>
                                <v-select class="text" :items="action_list" v-model="selected_action" item-text="name"
                                    item-value="id" autocomplete="off" outlined dense clearable required></v-select>
                                <label class="label">IMPORT CSV</label><span
                                    style="color: red; font-weight: bolder">*</span>
                                <v-file-input dense v-model="file" persistent-hint clearable color="rgb(1, 127, 152)"
                                    label="Choose File" accept=".csv" prepend-icon="mdi-paperclip" outlined show-size>
                                </v-file-input>


                                <v-btn :loading="output_load" @click="importCsv" class="button">
                                    Import
                                    <v-icon right>mdi-cloud-upload</v-icon>
                                </v-btn>

                            </v-col>

                        </v-row>
                    </v-form>
                </div>

                <template>

                    <div v-if="Array.isArray(failList) && failList.length > 0">
                        <h5 class="mt-4" style="text-align: center; color: red;">Failed Record</h5>
                    </div>
                    <v-simple-table v-if="Array.isArray(failList) && failList.length">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">PRN No./Employee Code</th>
                                    <th class="text-left">New Email</th>
                                    <th class="text-left">Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in failList" :key="item.name">
                                    <td>{{ item.prn }}</td>
                                    <td>{{ item.email }}</td>
                                    <td style="color: red;">{{ item.reason }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <div v-if="Array.isArray(success_list) && success_list.length > 0">
                        <h5 class="mt-4" style="text-align: center; color: green;">Success Record</h5>
                    </div>
                    <v-simple-table v-if="Array.isArray(success_list) && success_list.length">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">PRN No./Employee Code</th>
                                    <th class="text-left">New Email</th>
                                    <th class="text-left">Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in success_list" :key="item.name">
                                    <td>{{ item.prn }}</td>
                                    <td>{{ item.email }}</td>
                                    <td style="color: green;">{{ item.reason }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>

                <!-- <template>
                    <h5 v-if="Array.isArray(success_list) && success_list.length" class="mt-4"
                        style="text-align: center; color: red;">Failed Record</h5>
                    
                </template> -->


            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css";
export default {
    data: () => ({
        valid: false,
        overlay: false,
        failList: [],
        success_list: [],
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Bulk Reset Username',
                disabled: true,
                href: 'breadcrumbs_link_2',
            },
        ],
        action_list: [

            {
                id: "1",
                name: 'Username'
            }
            , { id: "2", name: 'Email' }],
        selected_action: "",
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",
        file: null,
        dialog: false,
        fileStatus: true,


        isLoading: false,
        output_load: false,
        rules: {
            required: (value) => !!value || "Required.",
        },
    }),

    methods: {
        importCsv() {

            let formData = new FormData();


            if (this.selected_action == '') {
                this.showSnackbar("red", "Please Select Action");
                return
            }
            if (this.file == null) {
                this.showSnackbar("red", "Please Select File");
                return
            }
            if (!(this.file.name.toLowerCase().includes(".csv") || this.file.name.toLowerCase().includes(".CSV"))) {
                this.showSnackbar("red", "File must be in CSV format Only.!");
                return
            }

            this.fileStatus = true;
            formData.append("file", this.file);
            formData.append("selected_action", this.selected_action)
            this.isLoading = true;
            axios
                .post("ImportData/bulkresetusernmae", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {

                    this.failList = res.data.failList;
                    this.success_list = res.data.success_list;

                    this.isLoading = false;
                    //  this.showSnackbar("green", "File Imported.");
                    this.file = null;
                    if (res.data.status == "200") {
                        this.showSnackbar("#4CAF50", "File Imported successfully...");

                        this.$refs.form.reset()
                    }
                })




        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },

        close() {
            this.dialog = false;
        },
    },
};
</script>
<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}

.fields {
    padding: 0px !important;
}

.add-section {
    display: block;
}

.import-box {
    margin: 1rem 1rem 2rem 1rem;
    border: 2px solid;
    padding: 1rem 2rem 1rem 2rem;
    text-align: center;
    background: #ffffffb5;
}

.label {
    color: black;
    font-size: 16px;
    font-weight: 400;
}

.button {
    background: white !important;
}

.set-btn {
    margin: 0rem 1rem 0rem 1rem;
    padding-top: 1rem;
}

.button:hover {
    color: white;
    background: black !important;
}

.file-warn {
    font-style: italic;
    color: red;
    text-align: center;
    margin: 0;
}
</style>
